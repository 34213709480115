import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Advertisement from "@/components/Advertisement.vue";
import {
    apiHome
} from "../../../../request/api.js";
export default {
    name: 'Video',
    components: {
        Header,
        Footer,
        Advertisement
    }, //添加注册组件事件
    data() {
        return {
            /*图片切换的设置*/
            swiperOptionImg: {
                pagination: {
                    el: '.swiper-paginationImg',
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                watchSlidesProgress: true,
                slidesPerView: 'auto',
                centeredSlides: true,
                loop: true,
                loopedSlides: 3,
                autoplay: {
                    disableOnInteraction: false,
                    delay: 2000,
                    pauseOnMouseEnter: true, //悬停停止切换
                },
                on: {
                    progress: function() {
                        for (let i = 0; i < this.slides.length; i++) {
                            var slide = this.slides.eq(i);
                            var slideProgress = this.slides[i].progress;
                            let modify = 1;
                            if (Math.abs(slideProgress) > 1) {
                                modify = (Math.abs(slideProgress) - 1) * 0.3;
                            }
                            let translate = slideProgress * modify * 680 + 'px';
                            let scale = 1 - Math.abs(slideProgress) / 5;
                            let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                            slide.transform('translateX(' + translate + ') scale(' + scale + ')');
                            slide.css('zIndex', zIndex);
                            slide.css('opacity', 1);
                            if (Math.abs(slideProgress) > 3) {
                                slide.css('opacity', 0);
                            }
                        }
                    },
                    setTransition: function(swiper, transition) {
                        for (var i = 0; i < this.slides.length; i++) {
                            var slide = this.slides.eq(i)
                            slide.transition(transition);
                        }
                    },
                    slideChangeTransitionEnd: function() {
                        this.Imgindexs = this.realIndex //切换结束时，告诉我现在是第几个slide
                    },
                }
            },


            /*车列表切换的设置*/
            swiperOption: {
                pagination: {
                    el: '.swiper-pagination',
                    clickable: false // 允许点击小圆点跳转
                },
                speed: 700,
                allowTouchMove: false,
                centeredSlides: true,
                spaceBetween: 50,
                slidesOffsetBefore: 40,
                loop: true,
                loopedSlides: 3,
                slidesPerView: 'auto',
                on: {
                    slideChangeTransitionEnd: function() {
                        this.carindexs = this.realIndex //切换结束时，告诉我现在是第几个slide
                    },
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            },

            twoInd: 0,
            slogan: '', // 视频slogan
            homeData: {}, //首页数据
            carindexs: 0, //当前的车
            Imgindexs: 0, //当前的图片

            // 首页视频配置
            videoSrc: "",
            videoImg: "",
            isMute: true,
            isPlay: true,
            loop: true,
            muted: true,
            autoplay: true,
            poster: false,
            preload: true,

            // 全屏滚动配置
            options: {
                licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
                afterLoad: this.afterLoad,
                //是否显示导航，默认为false
                navigation: true,
                //为每个section设置背景色
                sectionsColor: [
                    "#ffffff",
                    "#ffffff",
                    "#ffffff",
                    "#ffffff",
                    "#1b1b1b",
                    "#ffffff",
                    "#000000",
                ],
            },

            // 热门车型数据
            cardData: [],

            ispage: 0, //控制广告的显示

            channel_title: "",
            channel_subtitle: "",
            pageindex: 0,
            homeDatathree: [], //用于截取数据品牌力大于5个截取五个
            certifySwiper: "",
        };
    },
    created() {
        this.home()
            /*车切换*/
        this.swiperOption.on.slideChangeTransitionEnd = function(swiper) {
            this.carindexs = swiper.realIndex;
        }.bind(this);
        /*图片切换*/
        this.swiperOptionImg.on.slideChangeTransitionEnd = function(swiper) {
            this.Imgindexs = swiper.realIndex;
        }.bind(this);
    },
    mounted() {

    },
    methods: {
        // 热门车型切换方法
        toggleFun(p) {
            this.cardData = this.cardData.map((item, index, array) => {
                if (index === array.length - 1 && p === 1) {
                    item = array[0]
                } else if (index === 0 && p === -1) {
                    item = array[array.length - 1];
                } else {
                    item = array[index + p];
                }
                return item;
            })
        },
        // 获取首页数据
        home() {
            apiHome({}).then(res => {
                if (res.data.code == 200) {
                    let homeData = res.data.data
                    this.homeData = homeData
                    this.videoSrc = homeData.one.video
                    this.videoImg = homeData.one.image
                    this.slogan = homeData.one.slogan
                    this.cardData = homeData.two.carList
                    if (homeData.six.length < 1) {
                        this.sectionsColor = [
                            "#ffffff",
                            "#ffffff",
                            "#ffffff",
                            "#ffffff",
                            "#1b1b1b",
                            "#ffffff",
                        ]
                    }
                    if (res.data.data.three.length >= 6) {
                        this.homeDatathree = res.data.data.three.slice(0, 5);
                    } else {
                        this.homeDatathree = res.data.data.three;
                    }
                    this.channel_title = res.data.data.seven.channel_title
                    this.channel_subtitle = res.data.data.seven.channel_subtitle

                    this.carlength = res.data.data.two.carList.length
                }
            });
        },
        // 全屏滚动回调
        afterLoad(origin, destination) {
            this.$refs.mychild.parentHandleclick(destination.index);
            this.ispage = destination.index
        },
        // 第二屏点击切换
        twoButton(index) {
            this.twoInd = index
        },
        // 跳转枫叶视界详情
        LinkDetails(id, title, typeid) {
            window.location.href = this.FunPublic.url + "newDetails.html?" + "id=" + escape(id) + "&title=" + escape(title) + "&plateId=" + escape(typeid)
        },
        carousel(e) {
            this.pageindex = e
        },
        /*车列表切换方法*/
        swiperNext() {
            this.$refs.mySwiper.$swiper.slideNext()
        },
        swiperPrev() {
            this.$refs.mySwiper.$swiper.slidePrev()
        },
        /*切换图片轮播的方法*/
        perNext() {
            this.$refs.mySwiperImg.$swiper.slideNext()
        },
        perPrev() {
            this.$refs.mySwiperImg.$swiper.slidePrev()
        }
    },
};